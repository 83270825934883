import { useContext, useEffect } from "react";
import classNames from "clsx";
import {
  AlkalineCategoryType,
  AllRechargeIdType,
  categoryType,
  copperDurationArray,
  durationType,
  LiteCategoryType,
  liteDurationArray,
  Model,
  planType,
} from "../../../lib/plans.ts";
import { ModelContext } from "./planSectionContext.tsx";
import { cn } from "../../../lib/utils.ts";

export type planNameType<M extends Model> = M extends "ALIVE"
  ? categoryType
  : M extends "LITE"
  ? LiteCategoryType
  : AlkalineCategoryType;

interface SelectablePlanNameListProps {
  planData: planType<AllRechargeIdType>;
  planNames: readonly planNameType<Model>[];
  selectedPlanName: planNameType<Model>;
  setSelectedPlanName: React.Dispatch<
    categoryType | LiteCategoryType | AlkalineCategoryType
  >;
}
export function SelectablePlanNameList({
  planData,
  planNames,
  selectedPlanName,
  setSelectedPlanName,
}: SelectablePlanNameListProps) {
  const { modelStateContext } = useContext(ModelContext);
  useEffect(() => {
    if (!planNames.includes(selectedPlanName))
      if (modelStateContext === "ALIVE") setSelectedPlanName("SOLO");
      else if (modelStateContext === "LITE") setSelectedPlanName("BASIC");
      else setSelectedPlanName("STANDARD");
  }, [modelStateContext]);
  return (
    <div
      className={classNames(
        "flex flex-wrap gap-2",
        planNames.length == 2 && "-sm:flex-col"
      )}
    >
      {planNames.map((planName) => (
        <SelectableCard
          key={planName}
          text1={planName}
          text2={
            // @ts-expect-error Property 'SOLO' does not exist on type 'planType<AllRechargeIdType>'
            planData[planName]["28"]["litres"] !== "unlimited"
              ? // @ts-expect-error Property 'SOLO' does not exist on type 'planType<AllRechargeIdType>'
                String(planData[planName]["28"]["litres"]) + " ltrs/m"
              : window.innerWidth > 768
              ? "Unlimited ltrs/m"
              : "Unltd  ltrs/m"
          }
          isSelected={planName === selectedPlanName}
        >
          <input
            title="planName"
            type="radio"
            name="planName"
            className={classNames("hidden")}
            value={planName}
            checked={planName === selectedPlanName}
            onClick={() => setSelectedPlanName(planName)}
          />
        </SelectableCard>
      ))}
    </div>
  );
}

function SelectableCard({
  text1,
  text2 = "",
  isSelected = false,
  children,
}: {
  text1: string;
  text2?: string;
  isSelected?: boolean;
  children: React.ReactNode;
}) {
  return (
    <label
      className={cn(
        "flex-[auto_1_45%] min-w-[90px]",
        "flex sm:flex-col sm:gap-1 justify-center items-center",
        "px-2 py-3 sm:px-[14px] sm:py-[8px]",
        isSelected
          ? "bg-primary-background outline-primary-primary font-bold sm:font-semibold text-primary-primary"
          : "bg-off-white outline-line font-semibold sm:font-medium ",
        "outline rounded-[8px] outline-1",
        "text-[13px] sm:text-[14px] leading-[120%]",
        "cursor-pointer"
      )}
    >
      {children}
      <h3 className={cn(isSelected ? "text-primary" : "text-title-active")}>
        {text1.slice(0, 1)}
        <span className={cn("lowercase")}>{text1.slice(1)}</span>
      </h3>
      {text2 && (
        <h3
          className={cn(
            "sm:border-t sm:pt-1",
            isSelected
              ? "border-primary text-primary"
              : "border-[#A0A3BD] sm:text-body"
          )}
        >
          <span className="sm:hidden">{" - "}</span>
          {text2}
        </h3>
      )}
    </label>
  );
}

interface SelectablePlanDurationListProps {
  modelState: Model;
  selectedDuration: durationType;
  setSelectedDuration: React.Dispatch<durationType>;
}

export function SelectablePlanDurationList({
  modelState,
  selectedDuration,
  setSelectedDuration,
}: SelectablePlanDurationListProps) {
  useEffect(() => {
    if (
      !(modelState === "ALIVE" ? copperDurationArray : liteDurationArray)
        // @ts-expect-error ...
        .includes(selectedDuration)
    )
      setSelectedDuration("28");
  }, [modelState]);
  return (
    <div className="flex gap-4">
      {(modelState === "ALIVE" ? copperDurationArray : liteDurationArray).map(
        (duration) => (
          <SelectableCard
            key={duration}
            text1={duration + " days"}
            isSelected={duration === selectedDuration}
          >
            <input
              title="duration"
              type="radio"
              name="planDuration"
              className="hidden"
              value={duration}
              checked={duration === selectedDuration}
              onClick={() => setSelectedDuration(duration)}
            />
          </SelectableCard>
        )
      )}
    </div>
  );
}

interface ShowPriceProps {
  price: number;
  discount: "string";
  savings: number;
}

export function ShowPrice({ price, discount, savings }: ShowPriceProps) {
  return (
    <div className="flex -sm:justify-between sm:gap-6">
      <h2 className="text-[32px] leading-tight font-extrabold text-success-success">
        ₹{price}
        <span className="text-xl text-body font-normal">/month </span>
      </h2>
      <div className="flex flex-col justify-center items-center py-2 px-6 bg-[#E0FF2233] rounded-[100px] border-b border-accent-dark">
        <p className="text-[10px] leading-[150%] font-medium text-body">
          {discount}% discount
        </p>
        <p className="text-success-success text-xs font-bold">
          Savings of ₹{savings}
        </p>
      </div>
    </div>
  );
}
