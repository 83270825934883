import { Icons } from "@src/components/icons";
import classNames from "clsx";
import { StepsData } from "../data";
import LeadFormPopupTriggerButton from "../../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import appIcon from "@src/vitehomepageassets/svgs/Others/app-icon.svg";

export const HowItWorks = () => {
  return (
    <div className="w-full max-w-[468px] md:px-6 md:py-6 md:border md:border-line md:rounded-2xl">
      <h3 className="text-titleActive text-[17px] md:text-2xl font-bold">
        How it works
      </h3>

      <div className="flex flex-col gap-[30px] mt-6">
        {StepsData.map((item, index) => (
          <HowItWorksSteps data={item} index={index} key={index} />
        ))}
        <LeadFormPopupTriggerButton
          formName="Homepage/Drinkprime-App/How-it-works"
          className="rounded-full px-10 py-5 md:px-12 md:py-[18px] !font-semibold text-sm md:text-[17px] max-w-[180px] md:max-w-full mx-auto bg-primary-primary w-full text-off-white text-nowrap"
          nextRoute={(params) => {
            window.location.href =
              "https://drinkprime.in/app/checkout/chooseplan?" + params;
          }}
        >
          Subscribe Now
        </LeadFormPopupTriggerButton>
      </div>
    </div>
  );
};

const HowItWorksSteps = ({
  data,
  index,
}: {
  data: (typeof StepsData)[number];
  index: number;
}) => {
  return (
    <div className="flex items-center gap-4 relative">
      <div
        className={classNames(
          "!size-[54px] flex items-center justify-center bg-primary-background rounded-xl p-[15px] relative",
          [0, 5].includes(index) && "self-start"
        )}
      >
        {data.icon === "app-icon" ? (
          <img src={appIcon} className="w-24 h-24" alt="" />
        ) : (
          <Icons name={data.icon} color="#4548b9" size={[24, 24]} />
        )}
        {index !== StepsData.length - 1 && (
          <Icons
            name="arrow-down"
            color="#00000040"
            size={[16, 16]}
            className={classNames(
              "absolute left-1/2 -bottom-[22px] -translate-x-1/2",
              index === 0 && "-bottom-[32px]"
            )}
          />
        )}
      </div>
      <div className="font-medium">
        <h4 className="!text-labelIconsGrey">Step {index + 1}:</h4>
        <p>{data.title}</p>
      </div>
    </div>
  );
};
