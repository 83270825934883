import { parseJSON } from "./urlLocalServices";

export const modelArray = ["ALIVE", "LITE", "ALKALINE"] as const;
export type Model = (typeof modelArray)[number];
export type ModelRechargeType = {
  [key in Model]: key extends "ALIVE"
    ? CopperRechargeIdType
    : key extends "LITE"
    ? LiteRechargeIdType
    : key extends "ALKALINE"
    ? AlkalineRechargeIdType
    : never;
};

export const copperPlanNameArray = [
  "SOLO",
  "COUPLE",
  "FAMILY",
  "UNLIMITED",
] as const;
export const litePlanNameArray = ["BASIC", "UNLIMITED"] as const;
export const alkalinePlanNameArray = ["STANDARD", "UNLIMITED"] as const;

export const copperDurationArray = ["28", "90", "360"] as const;
export const liteDurationArray = ["28", "360"] as const;

export type categoryType = (typeof copperPlanNameArray)[number];
export type LiteCategoryType = (typeof litePlanNameArray)[number];
export type AlkalineCategoryType = (typeof alkalinePlanNameArray)[number];
export type durationType = (typeof copperDurationArray)[number];
export type LitedurationType = (typeof liteDurationArray)[number];
export type PlanDataStructure<T extends Model> = {
  price: number;
  litres: number | "unlimited";
  rechargeId: ModelRechargeType[T];
  discount?: number;
  savings?: number;
  dprice?: number;
  total: number;
  duration: T extends "ALIVE" ? durationType : LitedurationType;
  model: T;
  people: T extends "ALIVE"
    ? categoryType
    : T extends "LITE"
    ? LiteCategoryType
    : AlkalineCategoryType;
  planName: string;
};

export type planType<T extends AllRechargeIdType> =
  T extends CopperRechargeIdType
    ? {
        [key in categoryType]: {
          [key in durationType]: PlanDataStructure<"ALIVE">;
        };
      }
    : T extends LiteRechargeIdType
    ? {
        [key in LiteCategoryType]: {
          [key in LitedurationType]: PlanDataStructure<"LITE">;
        };
      }
    : {
        [key in AlkalineCategoryType]: {
          [key in LitedurationType]: PlanDataStructure<"ALKALINE">;
        };
      };

// export const oldRechargeIds = [
//   "solo399_tp1",
//   "solo399_tp5",
//   // "solo399_tp6",
//   "solo399_tp7",
//   "cpl21_tp1",
//   "cpl21_tp5",
//   // "cpl21_tp6",
//   "cpl21_tp7",
//   "fam21_tp1",
//   "fam21_tp5",
//   // "fam21_tp6",
//   "fam21_tp7",
//   "unlt21_tp1",
//   "unlt21_tp5",
//   // "unlt21_tp6",
//   "unlt21_tp7",
// ] as const;

export const copperRechargeIds = [
  "solo449_tp1",
  "solo449_tp5",
  // "solo449_tp6",
  "solo449_tp7",
  "cpl21_tp1",
  "cpl21_tp5",
  // "cpl21_tp6",
  "cpl21_tp7",
  "fam21_tp1",
  "fam21_tp5",
  // "fam21_tp6",
  "fam21_tp7",
  "unlt21_tp1",
  "unlt21_tp5",
  // "unlt21_tp6",
  "unlt21_tp7",
] as const;
export const liteRechargeIds = [
  "DPL250L399PM_tp1",
  "DPL250L399PM_tp7",
  "DPLUnlt499PM_tp1",
  "DPLUnlt499PM_tp7",
] as const;
export const alkalineRechargeIds = [
  "alk250l549pm_tp2",
  "alk250l549pm_tp6",
  "alkunlt675pm_tp2",
  "alkunlt675pm_tp7",
] as const;
export const allRechargeIds = [
  ...copperRechargeIds,
  ...liteRechargeIds,
  ...alkalineRechargeIds,
];

export type CopperRechargeIdType = (typeof copperRechargeIds)[number];
export type LiteRechargeIdType = (typeof liteRechargeIds)[number];
export type AlkalineRechargeIdType = (typeof alkalineRechargeIds)[number];
export type AllRechargeIdType =
  | CopperRechargeIdType
  | LiteRechargeIdType
  | AlkalineRechargeIdType;
export type localStoragePlanType = {
  plan_type: categoryType;
  plan_duration: durationType;
};

export const defaultCopperPlans: planType<CopperRechargeIdType> = {
  SOLO: {
    28: {
      price: 449,
      dprice: 449,
      litres: 130,
      savings: 0,
      discount: 0,
      rechargeId: "solo449_tp1",
      total: 449,
      duration: "28",
      people: "SOLO",
      model: "ALIVE",
      planName: "SOLO130L449",
    },
    90: {
      litres: 390,
      price: 428,
      savings: 63,
      discount: 11,
      rechargeId: "solo449_tp5",
      total: 1284,
      dprice: 1347,
      duration: "90",
      people: "SOLO",
      model: "ALIVE",
      planName: "SOLO130L449",
    },
    360: {
      litres: 1560,
      price: 333,
      savings: 1389,
      discount: 22,
      rechargeId: "solo449_tp7",
      total: 3999,
      dprice: 5388,
      duration: "360",
      people: "SOLO",
      model: "ALIVE",
      planName: "SOLO130L449",
    },
  },
  COUPLE: {
    28: {
      litres: 200,
      price: 499,
      discount: 0,
      savings: 0,
      rechargeId: "cpl21_tp1",
      total: 499,
      dprice: 499,
      duration: "28",
      people: "COUPLE",
      model: "ALIVE",
      planName: "COUPLE", //according to old plan naming convention
    },
    90: {
      price: 476,
      litres: 600,
      discount: 11,
      savings: 175,
      rechargeId: "cpl21_tp5",
      total: 1429,
      dprice: 1604,
      duration: "90",
      people: "COUPLE",
      model: "ALIVE",
      planName: "COUPLE",
    },
    // 180: {
    //   price: 450,
    //   litres: 1200,
    //   discount: 16,
    //   savings: 509,
    //   rechargeId: "cpl21_tp6",
    //   total: 2699,
    //   dprice: 3208,
    //   duration:"180",
    //   people:"COUPLE",
    //   model: "ALIVE",
    //   planName: "COUPLE",
    // },
    360: {
      price: 417,
      savings: 249,
      discount: 22,
      rechargeId: "cpl21_tp7",
      total: 4999,
      litres: 2400,
      dprice: 6416,
      duration: "360",
      people: "COUPLE",
      model: "ALIVE",
      planName: "COUPLE",
    },
  },
  FAMILY: {
    28: {
      savings: 0,
      rechargeId: "fam21_tp1",
      total: 599,
      litres: 500,
      price: 599,
      discount: 0,
      dprice: 599,
      duration: "28",
      people: "FAMILY",
      model: "ALIVE",
      planName: "FAMILY",
    },
    90: {
      price: 570,
      litres: 1500,
      discount: 11,
      savings: 216,
      rechargeId: "fam21_tp5",
      total: 1709,
      dprice: 1925,
      duration: "90",
      people: "FAMILY",
      model: "ALIVE",
      planName: "FAMILY",
    },
    // 180: {
    //   price: 541,
    //   discount: 16,
    //   savings: 602,
    //   rechargeId: "fam21_tp6",
    //   total: 3249,
    //   litres: 3000,
    //   dprice: 3851,
    //   duration:"180",
    //   people:"FAMILY",
    //   model: "ALIVE",
    //   planName: "FAMILY",
    // },
    360: {
      price: 500,
      savings: 1702,
      discount: 22,
      rechargeId: "fam21_tp7",
      total: 5999,
      litres: 6000,
      dprice: 7701,
      duration: "360",
      people: "FAMILY",
      model: "ALIVE",
      planName: "FAMILY",
    },
  },
  UNLIMITED: {
    28: {
      litres: "unlimited",
      savings: 0,
      discount: 0,
      rechargeId: "unlt21_tp1",
      total: 625,
      price: 625,
      dprice: 625,
      duration: "28",
      people: "UNLIMITED",
      model: "ALIVE",
      planName: "UNLIMITED",
    },
    90: {
      price: 596,
      discount: 11,
      savings: 220,
      rechargeId: "unlt21_tp5",
      total: 1789,
      dprice: 2009,
      litres: "unlimited",
      duration: "90",
      people: "UNLIMITED",
      model: "ALIVE",
      planName: "UNLIMITED",
    },
    // 180: {
    //   price: 563,
    //   discount: 16,
    //   rechargeId: "unlt21_tp6",
    //   savings: 639,
    //   total: 3379,
    //   dprice: 4018,
    //   litres: "unlimited",
    //   duration:"180",
    //   people:"UNLIMITED",
    //   model: "ALIVE",
    //   planName: "UNLIMITED",
    // },
    360: {
      price: 525,
      savings: 1737,
      discount: 22,
      rechargeId: "unlt21_tp7",
      total: 6299,
      dprice: 8036,
      litres: "unlimited",
      duration: "360",
      people: "UNLIMITED",
      model: "ALIVE",
      planName: "UNLIMITED",
    },
  },
};

export const defaultLitePlans: planType<LiteRechargeIdType> = {
  BASIC: {
    28: {
      price: 399,
      litres: 250,
      rechargeId: "DPL250L399PM_tp1",
      discount: 0,
      savings: 0,
      dprice: 399,
      total: 399,
      duration: "28",
      people: "BASIC",
      model: "LITE",
      planName: "DPL250L399PM",
    },
    360: {
      price: 299,
      savings: 1200,
      discount: 25,
      rechargeId: "DPL250L399PM_tp7",
      total: 3588,
      litres: 3000,
      dprice: 4788,
      duration: "360",
      people: "BASIC",
      model: "LITE",
      planName: "DPL250L399PM",
    },
  },
  UNLIMITED: {
    28: {
      litres: "unlimited",
      savings: 0,
      discount: 0,
      rechargeId: "DPLUnlt499PM_tp1",
      total: 499,
      price: 499,
      dprice: 499,
      duration: "28",
      people: "UNLIMITED",
      model: "LITE",
      planName: "DPLUnlt499PM",
    },
    360: {
      price: 400,
      savings: 1188,
      discount: 20,
      rechargeId: "DPLUnlt499PM_tp7",
      total: 4800,
      dprice: 5988,
      litres: "unlimited",
      duration: "360",
      people: "UNLIMITED",
      model: "LITE",
      planName: "DPLUnlt499PM",
    },
  },
};

export const defaultAlkalinePlans: planType<AlkalineRechargeIdType> = {
  STANDARD: {
    28: {
      price: 549,
      litres: 250,
      rechargeId: "alk250l549pm_tp2",
      discount: 0,
      savings: 0,
      dprice: 350,
      total: 350,
      duration: "28",
      people: "STANDARD",
      model: "ALKALINE",
      planName: "ALK250L549PM",
    },
    360: {
      price: 429,
      savings: 1438,
      discount: 22,
      rechargeId: "alk250l549pm_tp6",
      total: 5150,
      litres: 3000,
      dprice: 7060,
      duration: "360",
      people: "STANDARD",
      model: "ALKALINE",
      planName: "ALK250L549PM",
    },
  },
  UNLIMITED: {
    28: {
      litres: "unlimited",
      savings: 0,
      discount: 0,
      rechargeId: "alkunlt675pm_tp2",
      total: 675,
      price: 675,
      dprice: 675,
      duration: "28",
      people: "UNLIMITED",
      model: "ALKALINE",
      planName: "ALKUNLT675PM",
    },
    360: {
      price: 527,
      savings: 1780,
      discount: 22,
      rechargeId: "alkunlt675pm_tp7",
      total: 6320,
      dprice: 8680,
      litres: "unlimited",
      duration: "360",
      people: "UNLIMITED",
      model: "ALKALINE",
      planName: "ALKUNLT675PM",
    },
  },
};

export const newPlans: Record<
  cityNamesType,
  Record<Model, planType<AllRechargeIdType>>
> = {
  Bangalore: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
  Hyderabad: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
  Gurugram: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
  Delhi: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
  Noida: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
  Ghaziabad: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
  Faridabad: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
  Mumbai: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
  Pune: {
    ALIVE: defaultCopperPlans,
    LITE: defaultLitePlans,
    ALKALINE: defaultAlkalinePlans,
  },
};




export type cityValueType =
  | "Choose City"
  | (typeof cityNamesArray)[number]
  | "Others";

export type cityNamesType = (typeof cityNamesArray)[number];

export const cityNamesArray = [
  "Bangalore",
  "Hyderabad",
  "Gurugram",
  "Delhi",
  "Noida",
  "Ghaziabad",
  "Faridabad",
  "Mumbai",
  "Pune",
] as const;
export const setItemInLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log("error in setting item in local storage: ", error);
  }
};

export const getItemFromLocalStorage = (key: string): any | null => {
  try {
    const item = localStorage.getItem(key);
    if (item) {
      return parseJSON(item);
    } else {
      return null;
    }
  } catch (error) {
    if (window.location.pathname === "/app/checkout/failure") return null;
    console.log("error in getting item from local storage: ", error);
    return null;
  }
};

export function getPlanByRechargeId<T extends AllRechargeIdType>(
  rechargeId: T,
  city?: cityNamesType
) {
  const plan =
    (city && newPlans[city] && newPlans[city]["ALIVE"]) || defaultCopperPlans;
  for (const [planKey, values] of Object.entries(plan)) {
    for (const [duration, value] of Object.entries(values) as [
      durationType,
      PlanDataStructure<Model>
    ][]) {
      if (value.rechargeId === rechargeId) {
        return {
          plan_type: planKey,
          plan_duration: duration,
        };
      }
    }
  }
  return null;
}
