import { Images } from "@src/components/images";
import SectionHeading from "@src/components/ui/heading";
import { TestimonialCarousel } from "./components/testimonialCarousel";

export const Testimonials = () => {
  return (
    <section
      className="relative py-[60px] pb-[70px] px-4 md:p-[82px_50px_124px_70px] flex flex-col md:flex-row gap-5 "
      id="testimonials-section"
    >
      <Images
        isBgImg={true}
        name="bg/testimonial-desk"
        className="hidden md:block"
      />
      <Images
        isBgImg={true}
        name="bg/testimonial-mobile"
        className="md:hidden object-top"
      />

      <SectionHeading
        variant="light"
        className="md:max-w-[400px] xl:max-w-[500px] "
        headingStyle="!text-left leading-[33px] md:!leading-[62px]"
        descriptionStyle="!text-left leading-[19px] md:!leading-[24px]"
        description={
          <>
            1 in 3 new DrinkPrime users find us through a friend or family
            referral. <br /> Our happy customers understand the impact of pure
            drinking water on the health and wellness of the entire community.
          </>
        }
      >
        A Thriving Community Of Over 1 Million
      </SectionHeading>
      <TestimonialCarousel />
    </section>
  );
};
