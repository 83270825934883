// import { useState } from "react";
import classNames from "clsx";

// import { createPortal } from "react-dom";
import { Icons } from "../icons";
import { Button } from "./button";
import LeadFormPopupTriggerButton from "../../sections/LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import { Images } from "../images";
import { cn, scrollToId } from "../../lib/utils";

export default function StickyBar() {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        id="intersection_tracker"
        className={classNames("invisible absolute top-[80%] md:top-[100%]")}
        aria-hidden="true"
      ></div>

      <div
        id="scroll-widget"
        className={classNames(
          "flex flex-col w-full -bottom-[150vh]",
          "absolute !bg-transparent z-[999] !pointer-events-none"
        )}
      >
        <div className="flex flex-col items-end gap-3 !pointer-events-none !z-[999]">
          <LeadFormPopupTriggerButton
            className={cn(
              "mr-4",
              "w-[50px] h-[50px] md:w-[60px] md:h-[60px] !pointer-events-auto"
            )}
            nextRoute={() => {
              setTimeout(
                () => (window.location.href = "https://wa.link/61q8wq")
              );
            }}
            formName="homepage/sticky-whatsapp-cta"
          >
            <Images name="icon/whatsapp" alt="whatsapp logo" />
          </LeadFormPopupTriggerButton>
          <Button
            className={cn(
              "mr-4",
              "w-[50px] h-[50px] md:w-[60px] md:h-[60px] !pointer-events-auto rounded-[10px] border-[0.1px]"
            )}
            aria-label="Scroll to top"
            title="Scroll to top"
            onClick={(event) => {
              event.preventDefault();
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <Icons name="arrow-down" color="#FFF" className="rotate-180" />
          </Button>
          {/* floating strip */}
          <div
            className={cn(
              "w-full flex gap-5 md:gap-8 -sm:nth-n:flex-1",
              "p-4 lg:px-[100px]",
              "bg-primary-background backdrop-blur-[30px] !pointer-events-auto"
            )}
          >
            <button
              type="button"
              className={cn(
                "text-sm sm:text-lg lg:text-[20px] leading-[24px] font-semibold",
                "py-2 md:py-3 px-5 outline outline-[2px] outline-primary-primary rounded-[70px] text-primary-primary",
                "ml-auto"
              )}
              onClick={() => {
                scrollToId("plans-section");
              }}
            >
              View Products
            </button>
            <LeadFormPopupTriggerButton
              formName="homepage/sticky-booking-cta"
              nextRoute={(params) => {
                setTimeout(
                  () =>
                    (window.location.href =
                      `${
                        import.meta.env.VITE_ORIGIN
                      }/app/checkout/chooseplan?` + params)
                );
              }}
              className={cn(
                "text-sm sm:text-lg lg:text-[20px] leading-[24px] font-semibold",
                "py-2 md:py-3 px-8 bg-primary-primary text-off-white rounded-[70px]"
              )}
            >
              Book Now
            </LeadFormPopupTriggerButton>
          </div>
        </div>
      </div>

      {/* {isModalOpen && createPortal(<div></div>, document.body)} */}
    </>
  );
}
