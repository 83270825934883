import smartwaterSubscription from "../../vitehomepageassets/images/smartwater-subscription/smartwater-subscription.webp";
import LeadFormPopupTriggerButton from "../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";

function SmartWaterOnSubscription() {
  return (
    <section className="py-[40px] px-4 sm:px-10 lg:py-[70px]">
      {/* container */}
      <div className="md:flex md:gap-6 xl:gap-20 md:items-center max-w-[1250px] md:mx-auto">
        <SectionHeadings />
        <div className="md:max-w-[500px] md:flex-1 mt-5">
          <img src={smartwaterSubscription} alt="" />
        </div>
        <LeadFormPopupTriggerButton
          className="mt-4 block bg-primary-primary text-offWhite mx-auto w-[320px] md:hidden rounded-full py-4 font-semibold text-sm md:text-[17px] "
          nextRoute={(params) => {
            window.location.href =
              "https://drinkprime.in/app/checkout/chooseplan?" + params;
          }}
          formName="homepage/smart-ro-purifier"
        >
          Subscribe Now
        </LeadFormPopupTriggerButton>
      </div>
    </section>
  );
}

export default SmartWaterOnSubscription;

function SectionHeadings() {
  return (
    <div className="md:flex-1">
      <h2 className="text-primary-primary text-2xl font-bold sm:text-5xl xl:text-[64px] leading-[34px] xl:leading-[130%]">
        Smart RO Water Purifiers <br className="md:hidden" />
        <span className="text-titleActive -sm:text-nowrap">
          On Subscription
        </span>
      </h2>
      <p className="text-body text-sm sm:text-lg xl:text-xl mt-3 leading-[21px]">
        Tired of running out of water cans in the middle of the night? Or
        burning a hole in your pocket trying to maintain your water purifier?
        <br />
        <p className="font-bold md:font-semibold text-primary-primary mt-2">
          Switch to DrinkPrime's IoT-enabled smart water purifiers on
          subscription. Enjoy lifetime free maintenance and never worry about
          clean water again!
        </p>
      </p>

      <LeadFormPopupTriggerButton
        className="mt-4 w-[343px] bg-primary-primary text-offWhite hidden md:block rounded-full py-4 font-semibold text-sm md:text-[17px] "
        nextRoute={(params) => {
          window.location.href =
            "https://drinkprime.in/app/checkout/chooseplan?" + params;
        }}
        formName="homepage/smart-ro-purifier"
      >
        Subscribe Now
      </LeadFormPopupTriggerButton>
    </div>
  );
}
