export const dpAdvantageGridData = [
  {
    category: "SAFE Drinking Water",
    waterCan: { icon: "danger-symbol", text: "Unfit for drinking" },
    otherPurifiers: {
      icon: "caution-symbol",
      text: "Under or Over Purified Water",
    },
    drinkPrime: { icon: "success-tick", text: "Perfectly purified water" },
    drinkPrimeAdvantage: { text: "Pure & healthy drinking water" },
  },
  {
    category: "MULTISTAGE Purification",
    waterCan: { icon: "danger-symbol", text: "Unknown process" },
    otherPurifiers: {
      icon: "caution-symbol",
      text: "Options Available At High Costs",
    },
    drinkPrime: {
      icon: "success-tick",
      text: "RO + UV + MTDS with Copper or Alkaline filter",
    },
    drinkPrimeAdvantage: { text: "Advanced purification at best prices" },
  },
  {
    category: "AFFORDABLE Prices",
    waterCan: {
      icon: "caution-symbol",
      text: "₹2 - ₹4/litre ‎  ‎ ‎  ‎ ‎  ‎ ‎  ‎   No maintenance",
    },
    otherPurifiers: {
      icon: "danger-symbol",
      text: "₹20,000 to purchase ₹5,000/year to maintain",
    },
    drinkPrime: {
      icon: "success-tick",
      text: "Starts at ₹1/litre FREE maintenance",
    },
    drinkPrimeAdvantage: {
      text: "ZERO upfront cost Lifetime FREE maintenance",
    },
  },
  {
    category: "RELIABLE Service",
    waterCan: {
      icon: "caution-symbol",
      text: "Hassle to order, replace, transport",
    },
    otherPurifiers: { icon: "caution-symbol", text: "Manual coordination" },
    drinkPrime: {
      icon: "success-tick",
      text: "App for easy recharge & service requests",
    },
    drinkPrimeAdvantage: { text: "Tech-enabled service" },
  },
  {
    category: "TECH Enabled Features",
    waterCan: { icon: "caution-symbol", text: "NA" },
    otherPurifiers: { icon: "danger-symbol", text: "NA" },
    drinkPrime: {
      icon: "success-tick",
      text: "One-click tracking of consumption, water quality & filter health",
    },
    drinkPrimeAdvantage: { text: "IoT enabled SMART purifiers" },
  },
] as const;
