import { Icons, Logos } from "@src/components/icons";
import classNames from "clsx";
import { menuLinks } from "./data";
import styles from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@src/components/ui/dropdown-menu";
import LeadFormPopupTriggerButton from "../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";

export const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const hamMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobileMenuOpen) {
      hamMenuRef.current?.classList.add(styles["is-active"]);
      document.body.style.overflow = "hidden";
    } else {
      hamMenuRef.current?.classList.remove(styles["is-active"]);
      document.body.style.overflow = "unset";
    }
  }, [isMobileMenuOpen]);
  return (
    <nav className="w-full sticky top-0 z-[999] shadow-sm lg:bg-primary-primary h-[60px] md:h-[64px] flex justify-between items-center px-5 xl:px-[100px] bg-white">
      <div>
        <Logos name="dp-white" size={[140, 32]} className=" hidden lg:block" />
        <Logos name="dp-blue" className="block lg:hidden" />
      </div>

      <div className="flex justify-between">
        <ul className=" gap-5 text-offWhite font-medium hidden lg:flex">
          {menuLinks.map((link) => {
            if (link.subMenu) {
              return (
                <li key={link.name} className=" p-1">
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex gap-2 items-center cursor-pointer">
                      {link.name}{" "}
                      <Icons
                        name="select-dropdown"
                        size={[16, 16]}
                        color="#FCFCFC"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="relative z-[9999]">
                      {link.subMenu.map((subLink) => {
                        return (
                          <DropdownMenuItem key={subLink.name}>
                            <a
                              href={subLink.path}
                              className="p-1"
                              target={
                                ["blogs"].includes(subLink.name.toLowerCase())
                                  ? "_blank"
                                  : "_self"
                              }
                            >
                              {subLink.name}
                            </a>
                          </DropdownMenuItem>
                        );
                      })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </li>
              );
            } else {
              return (
                <li key={link.name} className="flex">
                  <a
                    href={link.path}
                    className="p-1"
                    target={
                      ["blogs"].includes(link.name.toLowerCase())
                        ? "_blank"
                        : "_self"
                    }
                  >
                    {link.name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </div>

      <Hamburger
        hamMenuRef={hamMenuRef}
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
        }}
      />
      <LeadFormPopupTriggerButton
        className="hidden lg:block bg-primary-background text-primary-primary rounded-full !text-sm px-6 py-2"
        nextRoute={(params) => {
          window.location.href =
            "https://drinkprime.in/app/checkout/chooseplan?" + params;
        }}
        formName="homepage/header"
      >
        Book Now
      </LeadFormPopupTriggerButton>

      {isMobileMenuOpen && (
        <MobileMenu
          navLinks={menuLinks}
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </nav>
  );
};

const Hamburger = ({
  hamMenuRef,
  onClick,
}: {
  hamMenuRef: React.RefObject<HTMLDivElement>;
  onClick?: () => void;
}) => {
  return (
    <div
      className={styles.hamburger}
      onClick={() => onClick?.()}
      ref={hamMenuRef}
    >
      <span className={styles.line}></span>
      <span className={styles.line}></span>
      <span className={styles.line}></span>
    </div>
  );
};

const MobileMenu = ({
  navLinks,
  onClick,
}: {
  navLinks: typeof menuLinks;
  onClick?: () => void;
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <div
      className="fixed top-0 right-0 w-full h-full bg-transparent z-50"
      onClick={() => onClick?.()}
    >
      <div
        className="fixed top-14 right-4  h-fit p-4 bg-white shadow-md z-50 border rounded-md"
        onClick={(e) => e.stopPropagation()}
      >
        <ul className="flex flex-col gap-2 text-offWhite font-medium">
          {navLinks.map((link) => {
            if (link.subMenu) {
              return (
                <li
                  key={link.name}
                  className=" cursor-pointer "
                  onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
                >
                  <div className="flex gap-2 items-center p-1 text-titleActive text-sm">
                    {link.name}{" "}
                    <Icons
                      name="select-dropdown"
                      size={[14, 14]}
                      color="#14142b"
                    />
                  </div>
                  <ul
                    className={classNames(
                      "flex flex-col gap-2 font-medium ml-2",
                      {
                        hidden: !isSubMenuOpen,
                      }
                    )}
                  >
                    {link.subMenu.map((subLink) => {
                      return (
                        <li key={subLink.name}>
                          <a
                            href={subLink.path}
                            className="p-1 text-titleActive text-sm"
                          >
                            {subLink.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            } else {
              return (
                <li key={link.name}>
                  <a
                    href={link.path}
                    className="p-1 text-titleActive text-sm inline-block w-full"
                    target={
                      ["blogs"].includes(link.name.toLowerCase())
                        ? "_blank"
                        : "_self"
                    }
                  >
                    {link.name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
        <LeadFormPopupTriggerButton
          className="bg-primary-background text-primary-primary rounded-full !text-sm px-6 py-2 mt-3"
          nextRoute={(params) => {
            window.location.href =
              "https://drinkprime.in/app/checkout/chooseplan?" + params;
          }}
          formName="homepage/header"
        >
          Book Now
        </LeadFormPopupTriggerButton>
      </div>
    </div>
  );
};
