import { useContext, useEffect, useRef, useState } from "react";
import classNames from "clsx";

import lp114_butt0_prod1 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product5.webp";
import lp114_butt0_prod1_black from "../../vitehomepageassets/images/plan-product/lp114_butt0_product5_black.webp";
import lp114_butt0_prod2 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product2.webp";
import lp114_butt0_prod3 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product3.webp";
import lp114_butt0_prod4 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product4.webp";
import lp114_butt0_prod5 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product1.webp";
import lp114_butt0_prod6 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product6.webp";
import lp114_butt0_prod7_black from "../../vitehomepageassets/images/plan-product/lp114_butt0_product7_black.webp";

import lp114_butt1_prod1 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product4.webp";
import lp114_butt1_prod2 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product2.webp";
import lp114_butt1_prod3 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product3.webp";
import lp114_butt1_prod4 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product1.webp";
import lp114_butt1_prod5 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product5.webp";

import alkaline_featureImg1 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img1.webp";
import alkaline_featureImg1_black from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img1_black.webp";
import alkaline_featureImg2 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img2.webp";
import alkaline_featureImg3 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img3.webp";
import alkaline_featureImg4 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img4.webp";
import alkaline_featureImg5_black from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img5_black.webp";
import { Icons } from "../../components/icons";
import { CityPopupContext } from "../../pages/homepage";
import isAssetColorThemeDark from "../header/cityPopup.tsx/utils";

const altText = [
  [
    "DrinkPrime RO UV Water Purifier with Multistage Purification System",
    "Goodness of Cooper in Water Purifier",
    "Best UV Water Purification for your water purifer",
    "Best Taste Adjuster - Water Purifier on Rent",
    "8 Litre Storage for your Drinkprime Water Purifier",
    "water purifier on rent that Retains Natural Minerals",
  ],
  [
    "Best Ro Membrane - Water Purifier on Rent",
    "Best Partical remover - Water Purifier on Rent",
    "Best Odour Remover - Water Purifier on Rent",
    "8 Litre Storage for your Drinkprime Water Purifier",
    "Real time monitoring through IOT Technology",
  ],
  [
    "8 Litre Storage for your Drinkprime Water Purifier",
    "Alkaline boast - Water Purifier on Rent",
    "DrinkPrime RO UV Water Purifier with Multistage Purification System",
  ],
];

export const ProductImagesCarousel = ({
  buttonIndex = 0,
}: {
  buttonIndex?: 0 | 1 | 2;
}) => {
  const { cityPopupSelection } = useContext(CityPopupContext);
  let isShownDarkAsset = isAssetColorThemeDark(cityPopupSelection);

  useEffect(() => {
    isShownDarkAsset = isAssetColorThemeDark(cityPopupSelection);
  }, [cityPopupSelection]);
  const imagesLp114_3d = [
    [
      isShownDarkAsset ? lp114_butt0_prod1_black : lp114_butt0_prod1,
      lp114_butt0_prod2,
      lp114_butt0_prod3,
      lp114_butt0_prod4,
      lp114_butt0_prod5,
      lp114_butt0_prod6,
    ],
    [
      lp114_butt1_prod1,
      lp114_butt1_prod2,
      lp114_butt1_prod3,
      lp114_butt1_prod4,
      lp114_butt1_prod5,
    ],
    [
      isShownDarkAsset ? alkaline_featureImg1_black : alkaline_featureImg1,
      alkaline_featureImg2,
      alkaline_featureImg3,
      alkaline_featureImg4,
    ],
  ];
  if (isShownDarkAsset) {
    imagesLp114_3d[0].push(lp114_butt0_prod7_black);
    imagesLp114_3d[2].push(alkaline_featureImg5_black);
  }
  const imagesLp114 = imagesLp114_3d[buttonIndex];
  const images = imagesLp114;
  const alt = altText[buttonIndex];

  const [activeImageNo, setActiveImageNo] = useState(0);
  const ref = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (ref.current) ref.current.scrollLeft = activeImageNo * 74;
  }, [activeImageNo]);
  useEffect(() => {
    setActiveImageNo(0);
    const intervalPointer = setInterval(
      () => setActiveImageNo((n) => (n + 1) % images.length),
      3000
    );
    return () => clearInterval(intervalPointer);
  }, [buttonIndex, images.length]);
  return (
    <div className="flex flex-col gap-4 max-w-[465px] w-full">
      <div
        data-component="tab-content"
        className="overflow-hidden h-[min(calc(100vw-30px),465px)]"
      >
        {/* last class is because between the transition - height becomes zero and scroll snaps */}
        <img
          src={images[activeImageNo]}
          alt={alt[activeImageNo]}
          className={classNames(
            "w-full rounded-2xl",
            "animate-slideTextFromRightAnimation"
          )}
          key={activeImageNo}
          loading="lazy"
        />
      </div>
      <div className="flex gap-3 justify-center">
        <div
          className="flex items-center w-[24px] flex-shrink-0 "
          onClick={() =>
            setActiveImageNo((prev) =>
              prev == 0 ? images.length - 1 : prev - 1
            )
          }
        >
          {/* <img src={arrow_left} alt="left-arrow" className="w-5 h-5" /> */}
          <Icons name="left-arrow" className="w-5 h-5" />
        </div>
        <ul
          ref={ref}
          data-component="tab-link"
          className={classNames(
            "flex gap-2.5 overflow-auto nth-n:flex-shrink-0",
            "py-2 px-1",
            "no-scrollbar"
          )}
        >
          {images.map((image, index) => (
            <li key={index}>
              <img
                src={image}
                alt={alt[index]}
                onClick={() => setActiveImageNo(index)}
                className={classNames(
                  "w-[64px] lg:w-[68px] rounded-lg cursor-pointer",
                  index == activeImageNo &&
                    "scale-110 border-2 border-primary-primary"
                )}
              />
            </li>
          ))}
        </ul>
        <div
          className="flex items-center w-[24px] flex-shrink-0"
          onClick={() =>
            setActiveImageNo((prev) =>
              prev == images.length - 1 ? 0 : prev + 1
            )
          }
        >
          <Icons name="left-arrow" className="w-5 h-5 rotate-180" />
        </div>
      </div>
    </div>
  );
};
