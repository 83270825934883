import { Icons, Logos } from "@src/components/icons";
import classNames from "clsx";
import { menuLinks } from "./data";
import styles from "./style.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@src/components/ui/dropdown-menu";
import LeadFormPopupTriggerButton from "../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import { LocationDialogButtonTrigger } from "./cityPopup.tsx/CityPopup";
import { cn } from "../../lib/utils";
import { CityPopupContext } from "../../pages/homepage";

export const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const hamMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobileMenuOpen) {
      hamMenuRef.current?.classList.add(styles["is-active"]);
      document.body.style.overflow = "hidden";
    } else {
      hamMenuRef.current?.classList.remove(styles["is-active"]);
      document.body.style.overflow = "unset";
    }
  }, [isMobileMenuOpen]);

  return (
    <nav className="w-full sticky top-0 z-[999] shadow-sm bg-primary-primary h-[60px] md:h-[64px] flex justify-between items-center px-5 xl:px-[100px]">
      <div>
        <div className="cursor-pointer"
          onClick={(event) => {
            event.preventDefault();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <Logos
            name="dp-white"
            size={[140, 32]}
            className=" hidden xl:block"
          />
          <Logos name="dp-white" className="block xl:hidden" />
        </div>
      </div>

      {/* desktop */}
      <div className="flex justify-between">
        <ul className=" gap-5 text-offWhite font-medium hidden lg:flex">
          {menuLinks.map((link) => {
            if (link.subMenu) {
              return (
                <li key={link.name} className=" p-1">
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex gap-2 items-center cursor-pointer">
                      {link.name}{" "}
                      <Icons
                        name="select-dropdown"
                        size={[16, 16]}
                        color="#FCFCFC"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="relative z-[9999]">
                      {link.subMenu.map((subLink) => {
                        return (
                          <DropdownMenuItem key={subLink.name}>
                            <a
                              href={subLink.path}
                              className="p-1"
                              target={
                                ["blogs"].includes(subLink.name.toLowerCase())
                                  ? "_blank"
                                  : "_self"
                              }
                            >
                              {subLink.name}
                            </a>
                          </DropdownMenuItem>
                        );
                      })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </li>
              );
            } else {
              return (
                <li key={link.name} className="flex">
                  <a
                    href={link.path}
                    // onClick={(e) => {
                    //   if (
                    //     (e.target as HTMLAnchorElement)
                    //       ?.getAttribute?.("href")
                    //       ?.startsWith("#")
                    //   ) {
                    //     e.preventDefault();
                    //     scrollToId(link.path.slice(1));
                    //   }
                    // }}
                    className="p-1"
                    target={
                      ["blogs"].includes(link.name.toLowerCase())
                        ? "_blank"
                        : "_self"
                    }
                  >
                    {link.name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </div>

      <LocationButton className="-lg:hidden mr-2" />

      {/* mobile */}
      <LocationButton className="ml-auto lg:hidden mr-5" />
      <Hamburger
        hamMenuRef={hamMenuRef}
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
        }}
      />

      {isMobileMenuOpen && (
        <MobileMenu
          navLinks={menuLinks}
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
    </nav>
  );
};

const Hamburger = ({
  hamMenuRef,
  onClick,
}: {
  hamMenuRef: React.RefObject<HTMLDivElement>;
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn(styles.hamburger, "lg:hidden")}
      onClick={() => onClick?.()}
      ref={hamMenuRef}
    >
      <span className={styles.line}></span>
      <span className={styles.line}></span>
      <span className={styles.line}></span>
    </div>
  );
};

const MobileMenu = ({
  navLinks,
  onClick,
}: {
  navLinks: typeof menuLinks;
  onClick?: () => void;
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <div
      className="fixed top-0 right-0 w-full h-full bg-transparent z-50"
      onClick={() => onClick?.()}
    >
      <div
        className="fixed top-14 right-4  h-fit p-4 bg-white shadow-md z-50 border rounded-md"
        onClick={(e) => e.stopPropagation()}
      >
        <ul className="flex flex-col gap-2 text-offWhite font-medium">
          {navLinks.map((link) => {
            if (link.subMenu) {
              return (
                <li
                  key={link.name}
                  className=" cursor-pointer "
                  onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
                >
                  <div className="flex gap-2 items-center p-1 text-titleActive text-sm">
                    {link.name}{" "}
                    <Icons
                      name="select-dropdown"
                      size={[14, 14]}
                      color="#14142b"
                    />
                  </div>
                  <ul
                    className={classNames(
                      "flex flex-col gap-2 font-medium ml-2",
                      {
                        hidden: !isSubMenuOpen,
                      }
                    )}
                  >
                    {link.subMenu.map((subLink) => {
                      return (
                        <li key={subLink.name}>
                          <a
                            href={subLink.path}
                            className="p-1 text-titleActive text-sm"
                          >
                            {subLink.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            } else {
              return (
                <li key={link.name}>
                  <a
                    href={link.path}
                    className="p-1 text-titleActive text-sm inline-block w-full"
                    target={
                      ["blogs"].includes(link.name.toLowerCase())
                        ? "_blank"
                        : "_self"
                    }
                  >
                    {link.name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
        <LeadFormPopupTriggerButton
          className="bg-primary-background text-primary-primary rounded-full !text-sm px-6 py-2 mt-3"
          nextRoute={(params) => {
            window.location.href =
              "https://drinkprime.in/app/checkout/chooseplan?" + params;
          }}
          formName="homepage/header"
        >
          Book Now
        </LeadFormPopupTriggerButton>
      </div>
    </div>
  );
};

function LocationButton({ className }: { className: string }) {
  const { cityPopupSelection, setIsCityPopupOpen } =
    useContext(CityPopupContext);
  return (
    <LocationDialogButtonTrigger
      className={cn(
        "text-sm text-off-white font-semibold flex gap-1.5 items-center",
        className
      )}
      onClick={() => setIsCityPopupOpen(true)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        className="translate-y-[2px] sm:translate-y-[1px]"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.09341 1.44666C4.91412 0.388744 7.15208 0.407235 8.95571 1.49509C10.7416 2.60511 11.827 4.58616 11.8169 6.71722C11.7754 8.8343 10.6115 10.8243 9.15662 12.3628C8.31692 13.2547 7.37757 14.0434 6.35776 14.7127C6.25273 14.7734 6.13768 14.8141 6.01829 14.8327C5.90339 14.8278 5.79149 14.7938 5.69268 14.7339C4.13575 13.7282 2.76984 12.4444 1.66067 10.9443C0.732547 9.69215 0.205253 8.17935 0.150392 6.61136C0.149186 4.47622 1.27269 2.50457 3.09341 1.44666ZM4.14501 7.49479C4.45128 8.24984 5.17419 8.74234 5.97621 8.74235C6.50162 8.74612 7.00669 8.53566 7.37888 8.15787C7.75106 7.78008 7.95944 7.26634 7.95758 6.73112C7.96038 5.91417 7.47942 5.17607 6.73926 4.86145C5.9991 4.54684 5.14574 4.71777 4.57764 5.29443C4.00953 5.8711 3.83874 6.73975 4.14501 7.49479Z"
          fill="#FCFCFC"
        />
        <ellipse
          opacity="0.4"
          cx="5.98307"
          cy="16.4993"
          rx="4.16667"
          ry="0.833333"
          fill="#FCFCFC"
        />
      </svg>
      <span>
        {cityPopupSelection == "Bangalore"
          ? "Bengaluru"
          : cityPopupSelection == "Gurugram"
          ? "Gurgaon"
          : cityPopupSelection || "city"}
      </span>
      <span className="transform rotate-180 scale-x-[2] font-thin">^</span>
    </LocationDialogButtonTrigger>
  );
}
