// import { useState } from "react";
import classNames from "clsx";

// import { createPortal } from "react-dom";
import { Icons } from "../icons";
import { Button } from "./button";
import LeadFormPopupTriggerButton from "../../sections/LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import { Images } from "../images";

export default function StickyBar() {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        id="intersection_tracker"
        className={classNames("invisible  top-[80%] md:top-[90%]")}
        aria-hidden="true"
      ></div>

      <div
        id="scroll-widget"
        className={classNames(
          "flex flex-col w-full right-0 -bottom-[150vh]  ",
          "absolute !bg-transparent z-[999] !pointer-events-none"
        )}
      >
        <div className="flex flex-col items-end gap-3 mr-4 mb-4 !pointer-events-none !z-[999]">
          {/* <button
            type="button"
            aria-label="Connect on Whatsapp"
            title="Connect on Whatsapp"
            className="w-[50px] h-[50px] md:w-[60px] md:h-[60px] !pointer-events-auto"
          >
            <Icons name="whatsapp-color-logo" className="w-full h-full" />
          </button> */}
          <LeadFormPopupTriggerButton
            className="w-[50px] h-[50px] md:w-[60px] md:h-[60px] !pointer-events-auto"
            nextRoute={() => {
              setTimeout(
                () => (window.location.href = "https://wa.link/61q8wq")
              );
            }}
            formName="homepage/sticky-whatsapp-cta"
          >
            <Images name="icon/whatsapp" />
          </LeadFormPopupTriggerButton>
          <Button
            className="w-[50px] h-[50px] md:w-[60px] md:h-[60px] !pointer-events-auto rounded-[10px] border-[0.1px]"
            aria-label="Scroll to top"
            title="Scroll to top"
            onClick={(event) => {
              event.preventDefault();
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <Icons name="arrow-down" color="#FFF" className="rotate-180" />
          </Button>
        </div>
      </div>

      {/* {isModalOpen && createPortal(<div></div>, document.body)} */}
    </>
  );
}
