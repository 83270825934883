import { Icons } from "@src/components/icons";
import { Images } from "@src/components/images";
import classNames from "clsx";

export const BlogCard = ({
  title,
  date,
  imgUrl,
}: {
  title: string;
  date: string;
  imgUrl: string;
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col md:flex-row gap-4",
        "px-4 py-3 md:px-6 md:py-5 rounded-lg md:rounded-[30px]",
        "border border-[#e7e8f1] max-w-[535px] "
      )}
    >
      <Images
        src={imgUrl}
        alt=""
        className="w-full h-[172px] md:w-[200px] md:h-[132px] rounded-lg object-cover object-center"
      />
      <div className="flex flex-col gap-2.5">
        <h2 className="md:text-[22px] text-black font-bold">{title}</h2>
        <p className="flex gap-1 text-body text-sm md:text-base font-medium items-center">
          <Icons name="clock" size={[20, 20]} />
          {date} By DrinkPrime
        </p>
      </div>
    </div>
  );
};
