import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormResetField,
} from "react-hook-form";
import { createContext, PropsWithChildren, useContext } from "react";
import {
  cityNamesArrayForLeadForm,
  LeadFormInputNamesArray,
  LeadFormInputNamesType,
} from "./utils";

import { cn } from "../../../lib/utils";

type useFormPropsType = {
  register: UseFormRegister<LeadFormInputNamesType>;
  handleSubmit: UseFormHandleSubmit<LeadFormInputNamesType, undefined>;
  formState: {
    errors: FieldErrors<LeadFormInputNamesType>;
  };
  resetField: UseFormResetField<LeadFormInputNamesType>;
  cityWatched: (typeof cityNamesArrayForLeadForm)[number];
};

export default function LeadFormInputWithErrorWrapper({
  useFormProps,
  className = "w-full",
  children,
}: PropsWithChildren<{
  useFormProps: useFormPropsType;
  className: string;
}>) {
  const {
    formState: { errors },
    cityWatched,
  } = useFormProps;

  const placeHolderData: Record<
    (typeof LeadFormInputNamesArray)[number],
    string
  > = {
    name: "Enter your name *",
    mobile: "Enter your phone number *",
    coupon: "Referral Code",
    city: "Choose city *",
    otherCity: "Enter your city name",
  };
  return (
    <>
      {LeadFormInputNamesArray.map((registerName) => (
        <div
          key={registerName}
          className={cn(
            registerName == "otherCity" && cityWatched !== "Others" && "hidden",
            className
          )}
        >
          <LeadFormInputContext.Provider
            value={{
              placeHolder: placeHolderData[registerName],
              registerName,
            }}
          >
            {children}
          </LeadFormInputContext.Provider>
          <p className={cn(`mt-1 ml-2 text-xs text-error-light`)}>
            <span>{errors[registerName]?.message}</span>
          </p>
        </div>
      ))}
    </>
  );
}

const LeadFormInputContext = createContext<{
  placeHolder: string;
  registerName: Partial<(typeof LeadFormInputNamesArray)[number]>;
}>({
  placeHolder: "Enter your name *",
  registerName: "name",
});

export function LeadFormInput({
  useFormProps,
  className,
}: {
  useFormProps: useFormPropsType;
  className: string;
}) {
  const { register, cityWatched } = useFormProps;
  const { placeHolder, registerName } = useContext(LeadFormInputContext);
  if (registerName !== "city")
    return (
      <input
        {...register(registerName)}
        placeholder={placeHolder}
        className={cn(
          registerName == "otherCity" && cityWatched !== "Others" && "hidden",
          className
        )}
      />
    );
  else
    return (
      <select
        {...register(registerName)}
        className={cn(
          !cityNamesArrayForLeadForm.includes(cityWatched) && "!text-black/40",
          className
        )}
        defaultValue="Choose City"
      >
        <option value="Choose City" disabled hidden className="text-black/40">
          {placeHolder}
        </option>
        {cityNamesArrayForLeadForm.map((city) => (
          <option key={city} value={city}>{city}</option>
        ))}
      </select>
    );
}
