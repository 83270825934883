import { cn } from "../../lib/utils";
import personHoldingGlass from "../../vitehomepageassets/images/leadForm/person-holding-glass.webp";

import useLeadForm from "./leadFormApi/useLeadForm";

import LeadFormInputWithErrorWrapper, {
  LeadFormInput,
} from "./leadFormApi/MainLeadFormInputs";
import { LeadFormSubmitButton } from "./leadFormApi/LeadFormSubmitButton";

function LeadFormSection() {
  const {
    useFormProps,
    setDummyValues,
    handleSubmit,
    isSubmitting,
    isSubmitted,
  } = useLeadForm();
  return (
    <section
      id="free-trial-section"
      className={cn(
        "px-4 py-[60px] xl:px-[60px]",
        "bg-primary-primary sm:text-center text-primary-white"
      )}
    >
      {/* container */}
      <div className={cn("mx-auto flex w-full max-w-screen-2xl gap-4")}>
        {/* Form block with headings */}
        <div className="flex flex-col gap-4 items-center flex-1">
          <FormSectionHeadings />
          <div
            className={cn(
              "flex w-full",
              "-sm:flex-col flex-wrap gap-5 sm:gap-4 items-center justify-center"
            )}
          >
            <LeadFormInputWithErrorWrapper
              useFormProps={useFormProps}
              className={cn("min-w-[300px] max-w-[450px] w-full")}
            >
              <LeadFormInput
                useFormProps={useFormProps}
                className={cn(
                  "rounded-full block mx-auto px-4 py-3 w-full max-w-[450px]",
                  "-sm:text-sm text-black"
                )}
              />
            </LeadFormInputWithErrorWrapper>
          </div>

          <LeadFormSubmitButton
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
            setDummyValues={setDummyValues}
            nextRoute={(params) => {
              window.location.href =
                "https://drinkprime.in/app/checkout/chooseplan?" + params;
            }}
            formName="homepage/7-day-trial-section"
            className={cn(
              "w-full max-w-[450px]",
              "rounded-full bg-primary-background py-2.5 border-[4px] border-primary-light",
              "text-sm md:text-lg font-semibold text-primary-primary",
              "flex flex-col items-center"
            )}
          >
            Book Now
          </LeadFormSubmitButton>

          <p className="text-xs leading-[20px] md:text-sm md:font-medium text-center">
            By creating an account on DrinkPrime, you agree to our{" "}
            <a
              className="inline underline text-nowrap"
              href={`${
                import.meta.env.VITE_ORIGIN || "https:drinkprime.in"
              }/app/terms-of-use`}
            >
              Terms of Use
            </a>
          </p>
        </div>

        <img
          src={personHoldingGlass}
          className="-lg:hidden w-[230px] self-center "
          alt="person-holding-a-glass-of-water"
        />
      </div>
    </section>
  );
}

export default LeadFormSection;

function FormSectionHeadings() {
  return (
    <div className="-sm:space-y-2">
      <h2 className="text-[20px] leading-[32px] font-bold sm:text-[30px] xl:text-[40px] xl:leading-[180%]">
        Start Your 7-Day Risk-Free Trial
      </h2>
      <p className="text-sm leading-[150%] sm:text-base xl:text-lg">
        Trusted by a community of{" "}
        <span className="text-accent-accent font-medium md:font-semibold">
          1M+ accross 9 cities
        </span>
      </p>
    </div>
  );
}

// interface LeadSubmitButtonProps {
//   handleSubmit: UseFormHandleSubmit<LeadFormInputNamesType, undefined>;
//   isSubmitting: boolean;
//   setDummyValues: () => void;
//   className?: string;
// }

// export function LeadSubmitButton({
//   handleSubmit,
//   isSubmitting,
//   setDummyValues,
//   className,
//   children
// }: PropsWithChildren<LeadSubmitButtonProps>) {
//   const [submitText, setSubmitText] = useState("Book Now");
//   return (
//     <button
//       type="button"
//       onClick={handleSubmit((data) => {
//         console.log(data);
//         setSubmitText("Submitting...");
//         handleLeadFormSubmission(data, "homepage/7-day-trial-section");
//         window.location.href = "https://drinkprime.in/app/checkout/chooseplan";
//         setSubmitText("Submit");
//       })}
//       disabled={isSubmitting}
//       className={cn(
//         "w-full max-w-[450px]",
//         "rounded-full bg-white py-2.5 border-[4px] border-primary-light",
//         "text-sm md:text-lg font-semibold text-primary-primary",
//         "flex flex-col items-center"
//       )}
//     >
//       {submitText}
//       {import.meta.env.VITE_MODE == "staging" ||
//       import.meta.env.VITE_MODE == "locale" ? (
//         <button
//           type="button"
//           className={classNames(
//             "mt-8 mx-auto bg-blue-100 py-1 px-4 rounded-full text-black/80",
//             "absolute scale-75"
//           )}
//           onClick={(e) => {
//             e.stopPropagation();
//             setDummyValues();
//           }}
//         >
//           Auto Fill
//         </button>
//       ) : null}
//     </button>
//   );
// }
