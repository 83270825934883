import classNames from "clsx";
import LeadFormPopupTriggerButton from "../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";

function HeroSection() {
  return (
    <section
      className={classNames(
        "p-4 xl:pl-[60px] flex lg:items-center",
        "hero-section",
        "h-[178vw] [@media(min-width:415px)]:-xs:h-[160vw] xs:-lg:h-[140vw] bg-cover bg-bottom",
        "lg:bg-cover lg:h-[500px] bg-no-repeat lg:bg-right-top 2xl:bg-right"
      )}
    >
      {/* text container */}
      <div className="-lg:text-center -lg:mx-auto w-full lg:w-1/4 xl:w-1/3">
        <h1 className="text-primary-primary text-3xl font-bold sm:text-[36px] xl:text-[56px] leading-[150%]">
          DrinkPrime
        </h1>
        <h2 className="text-titleActive text-[15px] leading-[130%] font-semibold sm:text-xl xl:text-[24px]">
          Smart Purification.
          <br />
          Free Maintenance.
        </h2>
        <p className="mt-2 sm:mt-[15px] text-titleActive text-[13px] sm:text-lg xl:text-[20px] leading-[150%]">
          Rent from our range of IoT-enabled water purifiers with lifetime free
          maintenance. RO + UV + MTDS with Copper filter, Alkaline filter &
          Mineraliser.
        </p>
        <LeadFormPopupTriggerButton
          className="mt-3 sm:mt-4 w-[240px] h-10 sm:h-12 flex justify-center items-center bg-primary-primary text-white rounded-full -lg:mx-auto"
          nextRoute={(params) => {
            window.location.href =
              "https://drinkprime.in/app/checkout/chooseplan?" + params;
          }}
          formName="homepage/hero-form"
        >
          BOOK NOW
        </LeadFormPopupTriggerButton>
      </div>
    </section>
  );
}

export default HeroSection;
