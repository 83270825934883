import classNames from "clsx";
import { Model } from "../../lib/plans";
import { Icons } from "@src/components/icons";

const gradientStyles = [
  "!bg-[linear-gradient(180deg,_#A45834_0%,_#C57947_40%,_#984D29_100%)] !text-white !font-bold !border-[#984D29]",
  "!bg-[linear-gradient(180deg,_#4144B0_0%,_#575ACB_39.34%,_#3437A4_94.79%)] !text-white !font-bold !border-[#4144B0]",
  "!bg-[linear-gradient(180deg,_#067D77_0%,_#2DA49A_39.34%,_#067D77_94.79%)] !text-white !font-bold !border-[#067D77]",
];

const activeBorderStyles = [
  "!border-[#984D29]",
  "!border-[#4144B0]",
  "!border-[#067D77]",
];
const caretStyles = [
  "!border-t-[#984D29]",
  "!border-t-[#4144B0]",
  "!border-t-[#067D77]",
];

const productNames = [
  "DrinkPrime Copper",
  "DrinkPrime Mineral +",
  "DrinkPrime Alkaline",
];

export function PlanButtonGroup({
  activeIndex,
  setActiveIndex,
  api,
}: {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  // eslint-disable-next-line
  api?: any;
}) {
  let showAlkaline = false;
  showAlkaline = true;
  return (
    <div className="w-full flex justify-center items-end gap-3 md:gap-4">
      {productNames.slice(0, showAlkaline ? 3 : 2).map((name, index) => (
        <div
          key={name}
          className="relative basis-1/3 max-w-[120px] md:max-w-[260px] grow"
        >
          {[0, 2].includes(index) && (
            <div
              className={classNames(
                "bg-[linear-gradient(105deg,_#F8F0FF_7.28%,_#F2F6FF_58.8%,_#E4F7FF_109.26%)] pt-[5px] pb-3 ",
                "rounded-t-[8px] text-center text-primary-primary text-[10px] md:text-sm",
                "relative top-2.5"
              )}
            >
              <p
                className={classNames(
                  "flex gap-1 justify-center font-medium md:font-semibold",
                  index === 0 && "relative -left-1"
                )}
              >
                <Icons name="stars" className="w-4 h-4 md:w-5 md:h-5" />
                {index === 0 && "Bestseller"}
                {index === 2 && "Popular choice"}
              </p>
            </div>
          )}
          <button
            type="button"
            className={classNames(
              "py-1.5 md:py-[22px] border-[1.5px] text-[#14142B] border-[#4E4B66] w-full font-semibold leading-[17px] text-sm md:text-[20px]",
              "rounded-[8px] md:rounded-2xl bg-[#F7F7FC] transition-none focus-visible:!scale-100 hover:!scale-100 relative z-[1]",
              {
                // "md:!py-[11px] !text-[13px] font-semibold": helpPopup,
              },
              activeIndex === index && [
                gradientStyles[index],
                activeBorderStyles[index],
              ]
            )}
            onClick={() => {
              setActiveIndex(index);
              api?.scrollTo(index);
            }}
          >
            {name}
          </button>
          {activeIndex === index && (
            <div
              className={classNames(
                "absolute left-1/2 -translate-x-1/2",
                "border-8 w-0 h-0 border-x-transparent border-b-transparent",
                activeIndex === index && caretStyles[index]
              )}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
}

export default function PurifierButtonStates({
  modelState = "ALIVE",
  setModelState,
}: {
  modelState: Model;
  setModelState: React.Dispatch<React.SetStateAction<Model>>;
}) {
  return (
    <div className="flex gap-[10px] lg:gap-6 justify-center">
      <PlanButtonGroup
        activeIndex={modelState === "ALIVE" ? 0 : modelState === "LITE" ? 1 : 2}
        setActiveIndex={(index) => {
          if (index === 0) setModelState("ALIVE");
          if (index === 1) setModelState("LITE");
          if (index === 2) setModelState("ALKALINE");
        }}
        api={null}
      />
    </div>
  );
}
