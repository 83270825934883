import { Awards } from "./components/awardsTab";
import { Blogs } from "./components/blogTab";
import { Investors } from "./components/InvestorTab";
import blogImg from "@src/vitehomepageassets/images/uncategorised/blog_img.webp";

type ValidIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export const awardsImgsData = [...Array(8).fill(0)].map((_, index) => ({
  name: `awards/${(index + 1) as ValidIndex}` as const,
}));

export type AwardsType = (typeof awardsImgsData)[number]["name"];

type investorsValidIndex = 1 | 2 | 3 | 4 | 5 | 6;
export const investorsImgsData = [...Array(6).fill(0)].map((_, index) => ({
  name: `investors/${(index + 1) as investorsValidIndex}` as const,
}));

export type InvestorsType = (typeof investorsImgsData)[number]["name"];

export const tabs = {
  awards: {
    title: "Awards",
    Content: Awards,
    icon: "trophy",
  },
  investors: {
    title: "Our Investors",
    Content: Investors,
    icon: "team",
  },
  blogs: {
    title: "Top Blogs",
    Content: Blogs,
    icon: "publication",
  },
} as const;

// static blog data

export const blogData = [
  ...Array(8).fill({
    title: "Water Purification: A Global Lifeline",
    date: "August 12, 2024",
    imgUrl: blogImg,
  }),
] as {
  title: string;
  date: string;
  imgUrl: string;
}[];
