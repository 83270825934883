import { useContext, useEffect, useState } from "react";
import {
  AllRechargeIdType,
  defaultCopperPlans,
  Model,
} from "../../../lib/plans";
import { cn } from "../../../lib/utils";
import LeadFormPopupTriggerButton from "../../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import { PlanContext } from "./planSectionContext";

export default function ButtonGroup({
  className,
  modelState,
  planId
}: {
  className?: string;
  setIsFormPopup: React.Dispatch<React.SetStateAction<boolean>>;
  modelState: Model;
  planId:AllRechargeIdType
}) {
  const planData = useContext(PlanContext);
  useEffect(() => {
    const { setCategoryState, setDurationState } = planData;
    setCategoryState(
      modelState === "ALIVE"
        ? "SOLO"
        : modelState === "LITE"
        ? "BASIC"
        : "STANDARD"
    );
    setDurationState("28");
    // if (modelState === "ALIVE") {
    //   const planId1 = defaultCopperPlans["SOLO"]["28"].rechargeId;
    //   setPlanId(planId1);
    // }
  }, [modelState]);

  return (
    <div
      className={cn(
        "mb-4 sm:px-7 flex -sm:flex-col-reverse gap-3 sm:gap-4 w-full px-0.5",
        "nth-n:flex-1 nth-n:min-h-[48px]",
        "font-semibold text-sm sm:text-[17px]",
        className
      )}
    >
      <button
        type="button"
        className={cn(
           "hidden",
          "rounded-full outline outline-1 outline-primary-primary bg-white text-primary-primary"
        )}
        onClick={() =>
          window.open(
            `${import.meta.env.VITE_ORIGIN}/app/product_specifications`
          )
        }
      >
        Know More
      </button>
      <LeadFormPopupTriggerButton
        formName="homepage/plan-section"
        className="rounded-full bg-primary-primary text-off-white sm:text-white font-semibold"
        nextRoute={
          modelState === "ALIVE"
            ? (str: string) => {
                window.location.href = `https://drinkprime.in/app/checkout/address?model=${modelState}&planId=${planId}&${str}`;
              }
            : "thankYou"
        }
      >
        Subscribe Now
      </LeadFormPopupTriggerButton>
    </div>
  );
}
