import { CityShowcase } from "@src/sections/cityShowcase";
import { DpAppSection } from "@src/sections/dpAppSection";
import { SpotlightSection } from "@src/sections/spotlightSection";
import FormSection from "@src/sections/LeadFormSection/leadFormSection";
import { Testimonials } from "@src/sections/testimonials/testimonials";
import { FaqSection } from "@src/sections/Faqs/faqSection";
import { useEffect } from "react";
import { lpVisitService } from "@src/lib/visitObject";
import SmartWaterOnSubscription from "@src/sections/SmartWaterOnSubscription/SmartWaterOnSubscription";
import HeroSection from "@src/sections/HeroSection/HeroSection";
import { DpAdvantageGrid } from "@src/sections/DpAdvantageGrid/DpAdvantageGrid";
import { Header } from "@src/sections/header/header";
import { Footer } from "@src/sections/footer";
import { ProductPlanSection } from "../../sections/PlanCarouselSection";
import { BannerStrip } from "@src/sections/strips/bannerStrip";
import { bannerStripData } from "@src/sections/strips/data";
import FeatureHighlights from "@src/sections/strips/featureHighlightStrip";
import StickyBar from "@src/components/ui/stickyBar";

export const Homepage = () => {
  useEffect(() => {
    lpVisitService("homepage");
  }, []);
  useEffect(() => {
    const scroll_widget = document.getElementById("scroll-widget");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          scroll_widget?.classList.toggle("visible", !entry.isIntersecting);
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      }
    );

    const target = document.querySelector("#intersection_tracker");
    if (target) {
      observer.observe(target);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <Header />
      <HeroSection />
      <div className="hidden md:block">
        <BannerStrip stripData={bannerStripData} />
      </div>
      <div className="md:hidden">
        <BannerStrip stripData={[...bannerStripData, ...bannerStripData]} />
      </div>
      <StickyBar />
      <FeatureHighlights />
      <SmartWaterOnSubscription />
      <ProductPlanSection />
      <DpAdvantageGrid />
      <Testimonials />
      <DpAppSection />
      <SpotlightSection />
      <CityShowcase />
      <FormSection />
      <FaqSection />
      <Footer />
    </>
  );
};
