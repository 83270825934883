import classNames from "clsx";
import {
  alkalinePlanNameArray,
  AllRechargeIdType,
  copperPlanNameArray,
  defaultAlkalinePlans,
  defaultCopperPlans,
  defaultLitePlans,
  durationType,
  LitedurationType,
  litePlanNameArray,
  Model,
  planType,
} from "../../../lib/plans.ts";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { cn } from "@src/lib/utils";
import { ModelContext, PlanContext } from "./planSectionContext.tsx";

import ButtonGroup from "./ButtonGroup.tsx";
import {
  planNameType,
  SelectablePlanDurationList,
  SelectablePlanNameList,
  ShowPrice,
} from "./PlanListCards.tsx";
import CompareAllPlansPopupTriggerButton from "./AllPlansPopup copy.tsx";
import { Icons } from "../../../components/icons/index.tsx";

export default function PlanBlock() {
  const { modelStateContext: modelState } = useContext(ModelContext);
  const [isFormPopup, setIsFormPopup] = useState(false);
  const [isHelpPopup, setIsHelpPopup] = useState(false);
  const planData: planType<AllRechargeIdType> =
    modelState === "ALIVE"
      ? defaultCopperPlans
      : modelState == "LITE"
      ? defaultLitePlans
      : defaultAlkalinePlans;
  const planNames: readonly planNameType<Model>[] =
    modelState === "ALIVE"
      ? copperPlanNameArray
      : modelState === "LITE"
      ? litePlanNameArray
      : alkalinePlanNameArray;

  const [selectedPlanName, setSelectedPlanName] = useState<planNameType<Model>>(
    modelState === "ALIVE"
      ? "SOLO"
      : modelState === "LITE"
      ? "BASIC"
      : "STANDARD"
  );
  const [selectedDuration, setSelectedDuration] = useState<
    durationType | LitedurationType
  >("28");
  useEffect(() => {
    setSelectedPlanName(
      modelState === "ALIVE"
        ? "SOLO"
        : modelState === "LITE"
        ? "BASIC"
        : "STANDARD"
    );
    setSelectedDuration("28");
  }, [modelState]);

  // @ts-expect-error asdf
  const planData1 = planData?.[selectedPlanName]?.[selectedDuration];
  const [price, discount, savings] = [
    planData1?.["price"],
    planData1?.["discount"],
    planData1?.["savings"],
  ];

  return (
    <PlanContext.Provider
      value={{
        modelState,
        plan: planData,
        categoryState: selectedPlanName,
        setCategoryState: setSelectedPlanName,
        helpPopup: isHelpPopup,
        setHelpPopup: setIsHelpPopup,
        durationState: selectedDuration,
        setDurationState: setSelectedDuration as React.Dispatch<
          React.SetStateAction<"28" | "90" | "360" | "">
        >,
        formPopup: isFormPopup,
        setFormPopup: setIsFormPopup,
      }}
    >
      <>
        <div className="border border-[#A0A3BD] rounded-2xl max-w-[600px] text-title-active xl:min-w-[540px] w-full ">
          {/* plan header */}
          <PlanHeader modelState={modelState} />

          {/* Main Body */}
          <div className="sm:px-4 sm:py-5 space-y-4">
            <Step1Wrapper
              text={["Step 1", ": Choose Family Size (consumption)"]}
            >
              <SelectablePlanNameList
                planData={planData}
                planNames={planNames}
                selectedPlanName={selectedPlanName}
                setSelectedPlanName={setSelectedPlanName}
              />
            </Step1Wrapper>
            <Step2Wrapper text={["Step 2", ": Choose Tenure"]}>
              <SelectablePlanDurationList
                modelState={modelState}
                selectedDuration={selectedDuration || "28"}
                setSelectedDuration={setSelectedDuration}
              />
              <hr />
              <ShowPrice price={price} discount={discount} savings={savings} />
            </Step2Wrapper>

            <hr className="sm:-mx-4" />
            {/* Button Group*/}
          </div>
          <ButtonGroup
            className="-sm:hidden"
            setIsFormPopup={setIsFormPopup}
            modelState={modelState}
          />
        </div>
        <ButtonGroup
          className="sm:hidden"
          setIsFormPopup={setIsFormPopup}
          modelState={modelState}
        />
        {/* {isFormPopup &&
        createPortal(
          <FormRedirectContext.Provider
            value={{
              path: modelState === "ALIVE" ? "address" : "thankyou",
              modelState,
              planId:
                // @ts-expect-error
                planData?.[selectedPlanName]?.[selectedDuration]?.[
                  "rechargeId"
                ],
            }}
          >
            <FormNameContext.Provider value={"plan-product-section-form"}>
              <LeadModal setIsModalOpen={setIsFormPopup} />
            </FormNameContext.Provider>
          </FormRedirectContext.Provider>,
          document.body
        )}
      {isHelpPopup && createPortal(<PopupAllPlans />, document.body)} */}
      </>
    </PlanContext.Provider>
  );
}

function Step1Wrapper({
  text: [text1, text2],
  children,
}: PropsWithChildren<{ text: string[] }>) {
  return (
    <div className="px-3 py-5 sm:p-4 space-y-3 border-y sm:border-x border-[#E7E8F1]">
      <div className="flex">
        <p className="text-[14px] sm:text-[15px] leading-[24px] font-medium">
          <span className="font-semibold">{text1}</span> {text2}
        </p>
      </div>
      {children}
    </div>
  );
}
function Step2Wrapper({
  text: [text1, text2],
  children,
}: PropsWithChildren<{ text: string[] }>) {
  // const { setHelpPopup } = useContext(PlanContext);
  return (
    <div
      className={classNames(
        "px-3 py-5 sm:p-4 border-t sm:border-b sm:border-x border-[#E7E8F1]",
        "nth-2:mt-3 nth-[n+2]:mt-5"
      )}
    >
      <div className="flex justify-between">
        <p className="text-[14px] sm:text-[15px] leading-[24px] font-medium">
          <span className="font-semibold">{text1}</span> {text2}
        </p>
        {/* <CompareOurPlansButton setHelpPopup={setHelpPopup} /> */}
        {/* <p>Compare our plans</p> */}

        <CompareAllPlansPopupTriggerButton
          className={classNames(
            "block",
            "rounded-full py-2 px-4",
            "text-xs text-primary font-medium",
            "flex items-center justify-center gap-1 bg-[#F7F7FC]"
          )}
        >
          <Icons name="popupPlans/comparePlan" className="h-5 w-5"/>
          Compare our plans
        </CompareAllPlansPopupTriggerButton>
      </div>
      {children}
    </div>
  );
}

function PlanHeader({ modelState }: { modelState: Model }) {
  return (
    <>
      <div
        className={cn(
          "px-2 py-4 sm:px-4 sm:py-5 border-b border-[#A0A3BD] rounded-t-2xl",
          modelState === "ALIVE"
            ? "bg-[linear-gradient(180deg,#FFE7D8_0%,#FFF_100%)]"
            : modelState == "LITE"
            ? "bg-[linear-gradient(180deg,#D8D9FF_0%,#FFF_100%)]"
            : "bg-[linear-gradient(180deg,#D9FFFD_0%,#FFF_100%)]"
        )}
      >
        <h2 className="leading-[24px] sm:text-xl sm:font-medium font-semibold mb-2">
          Flexible Rental Plans
        </h2>
        <p className="text-xs text-title-active sm:text-sm sm:leading-[24px]">
          Security deposit of <span className="font-medium">₹1,500</span> will
          be{" "}
          <span className="text-success-success font-semibold">
            100% refundable
          </span>
        </p>
      </div>
    </>
  );
}
