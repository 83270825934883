export const linkSections = {
  premiumDrinkingWater: [
    { title: "Plans", href: "#plans-section" },
    { title: "How it works", href: "#how-it-works-section" },
    { title: "DrinkPrime advantage", href: "#drinkprime-advantage-section" },
    { title: "Customer stories", href: "#testimonials-section" },
    { title: "Get 7-days risk free trial", href: "#free-trial-section" },
  ],
  explore: [
    { title: "About", href: "https://www.drinkprime.in/about" },
    { title: "Careers", href: "https://drinkprime.in/careers" },
    { title: "News & events", href: "https://drinkprime.in/press" },
    { title: "Blog", href: "https://www.drinkprime.in/blog" },
  ],
  contactUs: [{ title: "FAQs", href: "#faqs-section" }],
  referAndEarn: [
    { title: "Refer now", href: "https://drinkprime.in/app/lp121" },
  ],
  cities: [
    // { title: "Water Purifier in Bangalore", href: "#" },
    // { title: "Water Purifier in Hyderabad", href: "#" },
    // { title: "Water Purifier in Delhi NCR", href: "#" },
    {
      title: "Water Purifier in Mumbai",
      href: "https://drinkprime.in/app/ro-water-purifier-on-rent-in-mumbai",
    },
    {
      title: "Water Purifier in Pune",
      href: "https://drinkprime.in/app/ro-water-purifier-on-rent-in-pune",
    },
  ],
};
