export const menuLinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Our Products",
    subMenu: [
      {
        name: "DrinkPrime Copper",
        path: "https://drinkprime.in/app/product_specifications",
      },
    ],
  },
  {
    name: "About Us",
    path: "https://drinkprime.in/about",
  },
  {
    name: "Refer & Earn",
    path: "https://drinkprime.in/app/lp121",
  },
  {
    name: "Blogs",
    path: "https://drinkprime.in/blog/",
  },
  {
    name: "Contact Us",
    path: "https://drinkprime.in/app/contact-us",
  },
];
