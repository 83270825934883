export const StepsData = [
  {
    icon: "choice",
    title: "Choose the product that suits you the best",
  },
  {
    icon: "clipboard-tick",
    title: "Book the Perfect Plan for You",
  },
  {
    icon: "notes",
    title: "Submit your details",
  },
  {
    icon: "ruppe-symbol",
    title: "Make the Payment",
  },
  {
    icon: "clock-countdown",
    title: "Get DrinkPrime Installed in 48 hours!",
  },
  {
    icon: "app-icon",
    title: "Connect your device to DrinkPrime app",
  },
] as const;

export const appCarouselImages = [
  { imageName: "app/recharge" },
  { imageName: "app/helpdesk" },
  { imageName: "app/plan" },
  { imageName: "app/water-report" },
  { imageName: "app/refer-and-earn" },
] as const;

export type AppCarouselImages = (typeof appCarouselImages)[number]["imageName"];
