import SectionHeading from "@src/components/ui/heading";
import { HowItWorks } from "./components/howItWorks";
import { AppCarousel } from "./components/appCarousel";

export const DpAppSection = () => {
  return (
    <section className="py-16 px-4" id="how-it-works-section">
      <SectionHeading
        className="max-w-[1200px] md:px-10 mx-auto pr-0.5 md:pr-auto"
        description="Track your water consumption, generate your personalised water quality report, and monitor your filter health using our innovative app. Recharging your device and raising service requests has never been easier."
      >
        <span className="text-primary-primary">The Drinkprime App:</span> <br className="md:hidden" /> Behold
        The Future of Water Purification
      </SectionHeading>

      <div className="max-w-[1150px] mx-auto mt-8 md:mt-12 flex flex-col gap-10 md:gap-4 lg:gap-8 items-center md:flex-row">
        <HowItWorks />
        <div className="flex md:w-full md:justify-center lg:px-4 xl:ml-10">
          <AppCarousel />
        </div>
      </div>
    </section>
  );
};
